import React, { FC } from "react";

import portrait from "../images/portrait-sw.png"
// import portrait from "../images/portrait-sw-sm.png"

export default function Portrait() {
    return (
        <div className="bg-center bg-no-repeat bg-contain bg-scroll" style={{ width: "30vw", height: "80vh", backgroundImage: `url(${portrait})` }}>
            {/* w-96 h-96 lg:w-128 lg:h-128  */}
        </div>
    );
}