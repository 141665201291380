import React, { FC } from "react";

const ToolCard: FC = (props) => {
  const { imagePath, title, details, imageClass, license } = props

  const backgroundStyle = { background: "linear-gradient(132deg, rgba(12,12,13), #FFFFFF)" }

  const labelContent = (d) => {
    if (d.length === 0) {
      return <div></div>
    }
    else {
      return <div className="basis-8 inline-block ml-2">
        <span className="inline-block p-1 rounded bg-secondary text-primary text-xs tracking-tighter">
          {d}
        </span>
      </div>
    }
  }

  return (
    <div className="basis-56 h-16 m-2 p-2 flex justify-between items-center shadow-lg bg-gradient-to-br from-slate-50 to-slate-100 hover:to-slate-200">
      {/* <div className="shrink grow"> */}
      <div className="mx-auto">
        <img
          className={imageClass}
          src={imagePath}
          alt={title}
        />
      </div>
      <span className="inline-block text-sm font-bold">
        {title}
      </span>
      {labelContent(details)}
    </div>
  );
}
export default ToolCard