import React, { useRef, FC } from "react";
import Portrait from "./Portrait";
import ConnectBar from "./ConnectBar";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Contact = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [captcha, setCaptcha] = React.useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_tb3b2sh', 'template_i0oinpq', form.current, '1o0Ktle34LxFu1wnV')
      .then((result) => {
        alert("Message sent!")
      }, (error) => {
        alert(error.text);
      });

  };

  return (
    <section id="contact" className="relative">
      <div className="container flex flex-row lg:flex-nowrap flex-wrap min-h-screen px-5 py-10 mt-8 mx-auto justify-evenly">
        <div className="flex flex-col md:pt-8 justify-center items-center">
          <span className="hidden lg:block pb-8">
            <Portrait />
          </span>
          <AnimationOnScroll animateIn="animate__fadeInUp" animateOut="animate__fadeOutUp">
            <ConnectBar />
          </AnimationOnScroll>
        </div>


        <form
          ref={form}
          name="contact"
          onSubmit={sendEmail}
          className="flex flex-col lg:w-1/3 w-full md:pt-8 items-center justify-center">
          {/* <h2 className="text-2xl xl:text-3xl mb-1 font-medium">
            Let's talk on Google Meet
          </h2> */}
          <a
            href="https://calendar.app.google/JfAJuUtxqFZLfowi7"
            className="text-primary text-center bg-secondary border-2 border-transparent w-56 mt-4 py-2 px-6 hover:border-inactive focus:ring-2 focus:ring-secondary rounded text-lg">
            Schedule Video Call
            {/* <ArrowRightIcon className="hidden md:inline w-4 h-4 ml-1" /> */}
          </a>
          <h2 className="text-lg xl:text-xl mb-1 mt-10 font-medium">
            ...or leave me a message
          </h2>
          <div className="relative w-full mb-4 text-lg text-inactive hover:text-primary duration-200 ease-in-out">
            <label htmlFor="name" className="leading-7">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              className="w-full bg-background-secondary rounded border border-inactive hover:border-primary focus:ring-2 focus:ring-secondary text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setName(e.target.value)}
            />
            {/* focus:border-primary focus:border-transparent */}
          </div>
          <div className="relative w-full mb-4 text-lg text-inactive hover:text-primary duration-200 ease-in-out">
            <label htmlFor="email" className="leading-7">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full bg-background-secondary rounded border border-inactive hover:border-primary focus:ring-2 focus:ring-secondary text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="relative w-full mb-4 text-lg text-inactive hover:text-primary duration-200 ease-in-out">
            <label
              htmlFor="message"
              className="leading-7">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full bg-background-secondary rounded border border-inactive hover:border-primary focus:ring-2 focus:ring-secondary text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <ReCAPTCHA
            sitekey="6LdPYe4hAAAAACLe-x5Pvxrzcz-oZcG0Dv4edSKL"
            onChange={(e) => setCaptcha(e)}
          />
          <button
            type="submit"
            className="text-primary text-center bg-secondary border-2 border-transparent w-56 mt-4 py-2 px-6 hover:border-inactive focus:ring-2 focus:ring-secondary rounded text-lg">
            Submit Message
          </button>

        </form>
      </div>
    </section >
  );
};

export default Contact
