import React, { FC } from "react";

const EducationCard: FC = (props) => {
  const {
    institution = "University",
    location = "Germany",
    degree = "Master",
    details = "Summary",
    from = "2020-01-01",
    to = "2020-02-01"
  } = props

  const lineDesignClasses = "relative before:block before:bg-secondary before:h-0.5 lg:before:w-6 md:before:w-4 before:w-2 before:absolute lg:before:-left-10 md:before:-left-7 before:-left-4 xl:before:top-2 lg:before:top-2 md:before:top-2 sm:before:top-2 before:top-1"


  return (
    <div className="w-72 xl:w-[32rem] lg:w-96 p-1 my-3 text-left lg:text-sm text-xs">
      <div className={lineDesignClasses}>
        <div className="flex flex-row justify-between">
          <div className="w-1/2">
            <p className="font-bold">{degree}</p>
            {/* <p className="">{moment(from).format('MMM. YYYY') + ' to ' + moment(to).format('MMM. YYYY')}</p> */}
          </div>
          {/* <div className="inline-block w-1/2 text-right">
            <p className="">{institution}, {location}</p>
          </div> */}
        </div>
        <p className="mt-1 italic">
          {details}
        </p>
      </div>
    </div>
  );
}
export default EducationCard