import React, { FC } from "react";
import FocusAreaCard from "./FocusAreaCard";
import {
  mdiLightbulbOn,
  mdiCloud,
  mdiRobotHappy,
  mdiAtom,
  mdiPlaylistCheck,
  mdiAccountGroupOutline,
  mdiCashMultiple,
  mdiCarMultiple,
  mdiLightningBolt,
  mdiRocketLaunch,
} from '@mdi/js';
import { general } from "../data";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useStaticQuery, graphql } from "gatsby"

const Portfolio: FC = (props) => {
  const cvDownload = useStaticQuery(graphql`
  {
    allFile(filter: {extension: {eq: "pdf"}, name: {eq: "CV_Stefan_Junk"}}) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
  `)

  const iconClasses = "w-8 h-8 mr-4 text-secondary inline-block"
  const focusAreaCardClasses = "basis-72 flex justify-start p-2 shadow-lg"
  const focusAreaTextClasses = "my-auto mx-auto"
  const menuOpenClasses = "inline-block p-2 font-medium text-xs leading-tight hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
  const menuOpenIconClasses = "w-4 h-4"
  const lineDesignClasses = "relative before:block before:bg-secondary before:h-0.5 lg:before:w-14 md:before:w-10 before:w-6 before:absolute lg:before:-left-20 md:before:-left-12 before:-left-8 xl:before:top-7 lg:before:top-6 md:before:top-4 sm:before:top-3 before:top-3"
  const backgroundStyle = { background: "linear-gradient(132deg, rgb(248 250 252), #FFFFFF)" }

  return (
    <section id="portfolio"
      className=""
      style={backgroundStyle}
    >
      <div className="container pb-5 lg:pb-10 mx-auto text-background-secondary">

        {/* Summary */}
        <div className="px-5 pt-20 mb-20 text-center">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4">
              Portfolio
            </h1>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <div className="xl:w-2/4 lg:w-3/4 mx-auto">
              <div className={lineDesignClasses}>
                <p className="text-base leading-relaxed ">{general.summary}</p>
              </div>
            </div>
          </AnimationOnScroll>
          <div className="my-10"></div>

          <AnimationOnScroll
            animateIn="animate__fadeInLeft"
          // animateOut="animate__fadeOutUp"
          >
            <a
              href={cvDownload.allFile.edges[0].node.publicURL}
              download
              className="py-2 px-6 rounded border-2 border-secondary text-base font-semibold focus:outline-none hover:border-secondary hover:bg-secondary hover:text-primary duration-500">
              Download My CV
            </a>
          </AnimationOnScroll>
        </div>

        {/* Focus Areas */}
        <div className="px-5 my-20 text-center">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <h3 className="sm:text-2xl text-xl font-medium title-font mb-4">
              Focus Areas
            </h3>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInLeft"
          // animateOut="animate__fadeOutUp"
          >
            <div className="flex flex-row flex-wrap justify-evenly">
              <FocusAreaCard
                iconPath={mdiLightbulbOn}
                title="Solution Design"
                details="11 Years Experience"
              />
              <FocusAreaCard
                iconPath={mdiCloud}
                title="Cloud Computing"
                details="4 Years Experience "
              />
              <FocusAreaCard
                iconPath={mdiPlaylistCheck}
                title="Requirements Management"
                details="4 Years Experience"
              />
              <FocusAreaCard
                iconPath={mdiAccountGroupOutline}
                title="Stakeholder Management"
                details="2 Years Experience"
              />
              <FocusAreaCard
                iconPath={mdiRobotHappy}
                title="Machine Learning"
                details="2 Years Experience"
              />
              <FocusAreaCard
                iconPath={mdiAtom}
                title="Quantum Computing"
                details="1 Year Experience"
              />
            </div>
          </AnimationOnScroll>
        </div>

        {/* Business Domains */}
        <div className="px-5 my-20 text-center">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <h3 className="sm:text-2xl text-xl font-medium title-font mb-4">
              Business Areas
            </h3>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInLeft"
          // animateOut="animate__fadeOutUp"
          >
            <div className="flex flex-row flex-wrap justify-evenly">
              <FocusAreaCard
                iconPath={mdiCashMultiple}
                title="Financial Services"
                details=""
              />
              <FocusAreaCard
                iconPath={mdiRocketLaunch}
                title="Space Technology"
                details=""
              />
              <FocusAreaCard
                iconPath={mdiCarMultiple}
                title="Automotive"
                details=""
              />
              <FocusAreaCard
                iconPath={mdiLightningBolt}
                title="Energy Supplier"
                details=""
              />
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </section >
  );
}

export default Portfolio