import React, { FC } from "react";
import { useState } from "react";
import moment from 'moment'


const ExperienceCard: FC = (props) => {
  const {
    title = "Cloud Advisory Consultant",
    company = "Accenture",
    location = "Berlin",
    from = "2020-09-01",
    to = "now",
    description = "Hybrid and multi cloud transformation projects in financial services.",
    bullets = [
      "Stakeholder management between cloud service providers and the client",
      "Requirements and delivery management - shaping and tracking work packages along the migration preparation",
      "Migration planning, landing zone design and cloud integration architecture for Google Cloud Platform and Microsoft Azure",
    ]
  } = props
  const [openDetails, setOpenDetails] = useState(false)

  const lineDesignClasses = "relative before:block before:bg-secondary before:h-0.5 lg:before:w-6 md:before:w-4 before:w-2 before:absolute lg:before:-left-10 md:before:-left-7 before:-left-4 xl:before:top-2 lg:before:top-2 md:before:top-2 sm:before:top-2 before:top-1"


  return (
    <div
      className="w-72 xl:w-[32rem] lg:w-96 p-1 my-3 text-left lg:text-sm text-xs"
    // onMouseEnter={() => setOpenDetails(true)}
    // onMouseLeave={() => setOpenDetails(false)}
    >
      <div className={lineDesignClasses}>
        <div className="flex flex-row justify-between">
          <div className="w-1/2">
            <p className="font-bold">{title}</p>
            <p className="">{moment(from).format('MMM. YYYY') + ' to ' + (to === 'now' ? 'now' : moment(to).format('MMM. YYYY'))}</p>
          </div>
          <div className="inline-block w-1/2 text-right">
            <p className="">{company + (location === "" ? '' : ', ') + location}</p>
          </div>
        </div>
        <p className="mt-1 italic">
          {description}
        </p>
        {openDetails ? (
          <ul className="list-inside list-none">
            {
              bullets.map(i => (
                <li className="flex flex-row">
                  <span className="inline-block mr-2 text-secondary font-bold">{'>'}</span>
                  <span className="iniine-block text-justify">{i}</span>
                </li>
              ))
            }
          </ul>
        ) : null
        }
      </div>
    </div>
  );
}
export default ExperienceCard