import React, { FC } from "react";
import ExperienceCard from "./ExperienceCard"
import { experiences } from "../data";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Experiences: FC = (props) => {
  const backgroundStyle = { background: "linear-gradient(132deg, rgb(248 250 252), #FFFFFF)" }

  return (
    <section id="experiences"
      className=""
    // style={backgroundStyle}
    >
      <div className="container mx-auto text-primary">

        {/* Experiences*/}
        <div className="px-5 my-20 text-center">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4">
              Experiences
            </h1>
          </AnimationOnScroll>
          <div className="flex flex-row flex-wrap justify-evenly">
            <AnimationOnScroll
              animateIn="animate__fadeInLeft"
            // animateOut="animate__fadeOutLeft"
            >
              <div className="basis-72">
                {
                  experiences.slice(0, Math.ceil((experiences.length / 2))).map(props => (
                    < ExperienceCard
                      title={props.title}
                      company={props.company}
                      location={props.location}
                      from={props.from}
                      to={props.to}
                      description={props.description}
                      bullets={props.bullets}
                    />
                  ))
                }
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInLeft"
            // animateOut="animate__fadeOutLeft"
            >
              <div className="basis-72">
                {
                  experiences.slice(Math.ceil((experiences.length / 2)), experiences.length).map(props => (
                    < ExperienceCard
                      title={props.title}
                      company={props.company}
                      location={props.location}
                      from={props.from}
                      to={props.to}
                      description={props.description}
                      bullets={props.bullets}
                    />
                  ))
                }

              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </section >
  );
}

export default Experiences