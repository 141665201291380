import React, { FC } from "react";
import { useState, useEffect } from "react";
import Icon from '@mdi/react'

const FocusAreaCard: FC = (props) => {
  const { iconPath, title, details } = props
  const [openMenu, setOpenMenu] = useState(false)

  const backgroundStyle = { background: "linear-gradient(132deg, rgba(12,12,13), #FFFFFF)" }

  const transitionStyle = {
    transitionProperty: 'height',
    transitionDuration: '500ms'
  }
  // transition-property: box-shadow;
  // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  // transition-duration: 150ms;

  // const invertState = (state) => {
  //   // return !state
  //   console.log(state)
  // }

  const labelContent = (d) => {
    if (d.length === 0) {
      return <div></div>
    }
    else {
      return <div className="inline-block ml-2">
        <span className="inline-block p-1 rounded bg-secondary text-primary text-xs tracking-tighter">
          {d}
        </span>
      </div>
    }
  }

  return (
    <div className="basis-56 m-2 p-2 flex flex-col justify-between shadow-lg bg-gradient-to-br from-slate-50 to-slate-100 hover:to-slate-200">
      <div className="flex justify-start items-center">
        <Icon
          className="basis-8 shrink-0 mr-2 text-secondary inline-block"
          path={iconPath}
          title={title}
        />
        <span className="inline-block mx-auto text-sm font-bold">
          {title}
        </span>
        {labelContent(details)}
      </div>
    </div>
  );
}
export default FocusAreaCard