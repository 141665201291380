import React, { FC } from "react";
import EducationCard from "./EducationCard"
import ToolCard from "./ToolCard"
import pythonLogo from "../images/tools/python-logo-generic.svg"
import googleLogo from "../images/tools/Google_Cloud_logo.svg"
import amazonLogo from "../images/tools/Amazon_Web_Services_Logo.svg"
import microsoftLogo from "../images/tools/Microsoft_Azure_Logo.svg"
import dockerLogo from "../images/tools/Docker_(container_engine)_logo.svg"
import terraformLogo from "../images/tools/Terraform_Logo.svg"
import tensorflowLogo from "../images/tools/TF_FullColor_Horizontal.svg"
import reactLogo from "../images/tools/React-icon.svg"
import { general, education } from "../data";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Component: FC = (props) => {
  const backgroundStyle = { background: "linear-gradient(132deg, rgb(248 250 252), #FFFFFF)" }

  return (
    <section id="tools"
      className="pt-10 pb-10"
      style={backgroundStyle}
    >
      <div className="container mx-auto text-background-secondary">

        {/* Tools */}
        <div className="px-5 my-20 text-center">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4">
              Tools
            </h1>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <div className="xl:w-2/4 lg:w-3/4 mb-5 mx-auto">
              <p className="text-base leading-relaxed ">{general.tools}</p>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInLeft"
          // animateOut="animate__fadeOutUp"
          >
            <div className="flex flex-row flex-wrap justify-evenly">
              <ToolCard
                imagePath={pythonLogo}
                title=""
                details="6 Years Experience"
                imageClass="h-8"
              />
              <ToolCard
                imagePath={googleLogo}
                title=""
                details="4 Years Experience"
                imageClass="h-5"
              />
              <ToolCard
                imagePath={amazonLogo}
                title=""
                details="4 Years Experience"
                imageClass="h-8"
              />
              <ToolCard
                imagePath={microsoftLogo}
                title=""
                details="4 Years Experience"
                imageClass="h-8"
              />
              <ToolCard
                imagePath={dockerLogo}
                title=""
                details="4 Years Experience"
                imageClass="h-8"
              />
              <ToolCard
                imagePath={terraformLogo}
                title=""
                details="3 Years Experience"
                imageClass="h-8"
              />
              <ToolCard
                imagePath={tensorflowLogo}
                title=""
                details="2 Years Experience"
                imageClass="h-16"
              />
              <ToolCard
                imagePath={reactLogo}
                title="React/JS"
                details="2 Years Experience"
                imageClass="h-8"
              />
            </div>
          </AnimationOnScroll>
        </div>

        {/* Education */}
        <div className="px-5 my-20 text-center">
          <AnimationOnScroll
            animateIn="animate__fadeInUp"
          // animateOut="animate__fadeOutUp"
          >
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4">
              Education
            </h1>
          </AnimationOnScroll>
          <div className="flex flex-row flex-wrap justify-evenly">
            <AnimationOnScroll
              animateIn="animate__fadeInLeft"
            // animateOut="animate__fadeOutLeft"
            >
              <div className="basis-72">
                {
                  education.slice(0, Math.ceil((education.length / 2))).map(props => (
                    < EducationCard
                      institution={props.institution}
                      location={props.location}
                      degree={props.degree}
                      details={props.details}
                      from={props.from}
                      to={props.to}
                    />
                  ))
                }
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInLeft"
            // animateOut="animate__fadeOutLeft"
            >
              <div className="basis-72">
                {
                  education.slice(Math.ceil((education.length / 2)), education.length).map(props => (
                    < EducationCard
                      institution={props.institution}
                      location={props.location}
                      degree={props.degree}
                      details={props.details}
                      from={props.from}
                      to={props.to}
                    />
                  ))
                }
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </section >
  );
}

export default Component