import React, { FC } from "react";
import Icon from '@mdi/react'
import { mdiLinkedin, mdiEmail, mdiMapMarker } from '@mdi/js';
import { general } from "../data";

const ConnectBar: FC = () => {
    const buttonClasses="block flex items-stretch p-1 m-1 xl:mr-0 mr-16 focus:outline-none hover:bg-secondary hover:text-primary duration-500 rounded"
    return (
        <div className="flex xl:flex-row flex-col">
            <a href={general.locationUrl} className={buttonClasses}>
                <div className="inline-block self-center">
                    <Icon
                        path={mdiMapMarker}
                        title="Location"
                        size={1} />
                </div>
                <div className="inline-block self-center px-2">{general.location}</div>
            </a>
            <a href={general.linkedin} className={buttonClasses}>
                <div className="inline-block self-center">
                    <Icon
                        path={mdiLinkedin}
                        title="Linkedin Profile"
                        size={1} />
                </div>
                <div className="inline-block self-center px-2">{general.linkedinShort}</div>
            </a>
            <a href={general.mailto} className={buttonClasses}>
                <div className="inline-block self-center">
                    <Icon
                        path={mdiEmail}
                        title="Send an Email"
                        size={1} />
                </div>
                <div className="inline-block self-center px-2">{general.email}</div>
            </a>
        </div>
    );
}

export default ConnectBar