import React, { FC } from "react";
import { general } from "../data";

export default function Navbar() {
  return (
    <header className="">
      <div className="container z-10 p-3 md:p-6 mx-auto flex flex-wrap items-center flex-col md:flex-row">
        <div className="mb-4 md:mb-0">
          <a href="#home" className="text-xl font-bold">
            {general.title}
          </a>
        </div>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex flex-wrap items-center justify-center text-base text-inactive font-medium ">
          <a href="#home" className="ml-5 mr-5 hover:text-primary duration-500">
            Home
          </a>
          <a href="#portfolio" className="ml-5 mr-5 hover:text-primary duration-500">
            Portfolio
          </a>
          <a href="#experiences" className="ml-5 mr-5 hover:text-primary duration-500">
            Experiences
          </a>
        </nav>
        <a
          href="#contact"
          className="mt-4 md:mt-0 py-1 px-3 rounded border-2 border-primary text-base font-semibold focus:outline-none hover:border-secondary hover:bg-secondary hover:text-primary duration-500">
          Contact Me
        </a>

      </div>
    </header>
  );
}